<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if='!loading && item'>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <h3 class='justify-center text-center text-break my-3 font-weight-bold headline p-1' style='color: #3F51B5'>
                {{ item.SchoolName }} - İNGİLİZCE <br> BİREYSEL DEĞERLENDİRME KARNESİ
              </h3>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class='justify-center text-center'>
                {{ item.StudentFullName }}
              </v-card-title>
              <v-card-title class='justify-center text-center my-0 py-0'>
                {{ item.StudentClassBranch }}
              </v-card-title>
              <v-card-title class='justify-center text-center text-break'>
                {{ item.FormName }} - {{ item.FormDate | moment }}
              </v-card-title>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined min-height="400">
              <apex-chart v-if="!loading" type='bar' height='350' :options='chartOptions' :series='series'></apex-chart>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment/moment'

export default {
  name: 'english-form-user-report',
  data() {
    return {
      formId: this.$route.params.id,
      rows: [],
      loading: true,
      pointTemplates: [],
      rateWatcher: false,
      item: {},
      series: [{
        name: 'PUAN',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          width: 350,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            endingShape: 'rounded',
            borderRadius: 3,
            distributed: true
          }
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        colors: ['#5867dd', '#ffb822', '#0abb87', '#fd397a'],
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: false
        },
        xaxis: {
          categories: [],
          labels: {
            rotate: -60
          }
        },
        yaxis: {
          show: false
        },
        fill: {
          opacity: 1
        },
        toolbar: {
          show: false
        },
        legend: {
          show: false
        },
        grid: {
          show: true
        },
        tooltip: {
          enabled: false
        }
      }
    }
  },
  watch: {
    rateWatcher(val) {
      if (val) this.rater(this.rows, this.pointTemplates)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('DD.MM.YYYY') : ''
    }
  },
  created() {
    this.getPointTemplates()
  },
  mounted() {
    this.getFormDetails()
    if (this.$vuetify.breakpoint.mobile) this.chartOptions.xaxis.labels.rotate = -60
  },
  methods: {
    rater(rows, pointTemplates) {
      this.loading = true
      //rate finder method start
      let pointTemplate =
          rows[0].FormUser.Form.PointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.PointTemplateId)
              : null
      if (pointTemplate) {
        pointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      let advancedPointTemplate =
          rows[0].FormUser.Form.AdvancedPointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.AdvancedPointTemplateId)
              : null
      if (advancedPointTemplate) {
        advancedPointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      rows.map((s) => {
        let rate = (s.Point / s.MaxPoint) * 100
        if (pointTemplate) {
          pointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.PointTemplate = d.Name
          })
        }
        if (advancedPointTemplate) {
          advancedPointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.AdvancedPointTemplate = d.Name
          })
        }
      })

      for (let i = 0; i < this.rows.length; i++) {
        this.chartOptions.xaxis.categories[i] = this.rows[i].AssessmentArea.Name != null ? this.rows[i].AssessmentArea.Name : ''
        // this.chartOptions.xaxis.categories[i][0] = this.rows[i].AssessmentArea.Name
        // this.chartOptions.xaxis.categories[i][1] = this.rows[i].AdvancedPointTemplate
        this.chartOptions.colors[i] = this.rows[i].AssessmentArea.Color
      }

      this.loading = false
      //rate finder method end
    },

    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/FormUserAssessmentArea', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        Query: {
          FormId: this.formId
        }
      })
          .then((data) => {
            this.rows = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;

            this.series = [{
              name: 'PUAN',
              data: this.rows.map((d) => d.Point)
            }]
            this.loading = false
          })
          .catch(({response}) => {
            this.loading = false
            ApiService.showError(response)
          })
    },

    getPointTemplates() {
      ApiService.setHeader()
      ApiService.post('api/PointTemplate', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.pointTemplates = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getFormDetails() {
      ApiService.setHeader()
      ApiService.get('api/Form/FormUserReport/' + 0 + '/' + this.formId)
          .then((data) => {
            this.item = data.data
            this.getDataFromApi()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
